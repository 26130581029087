import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Pagination } from "flowbite-react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Approved from "../../../../assets/images-svg/Approved";
import Dropdowns from "../../../../assets/images-svg/Drop_down";
import Filter from "../../../../assets/images-svg/Filter";
import LeftArrow from "../../../../assets/images-svg/LeftArrow";
import Reject from "../../../../assets/images-svg/Reject";
import RightArrow from "../../../../assets/images-svg/RightArrow";
import { axiosService } from "../../../../services/axios.service";
import sessionService from "../../../../services/sessionstorage.service";
import { LEAVE_URL } from "../../../leaves/constants/leave-urls";
import { REPORTEES_URL } from "../../../reportees/constants/reportees-urls";
import "./ReporteesAppliedLeaves.css";
const ReporteesAppliedLeaves = () => {
  const User = sessionService.getUserData("userData");
  const empId = User?.employee?.empId;
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const role = userData?.role;
  const [dates, setDates] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [data, setData] = useState([]);
  const [singleSelections, setSingleSelections] = useState(null);
  const [totalElements, setTotalElements] = useState(0);
  const cardsPerPageOptions = [10, 15, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(cardsPerPageOptions[0]);
  const [dialogType, setDialogType] = useState("");
  const [selectedLeave, setSelectedLeave] = useState({
    isHalfDay: false,
    halfType: "",
  });

  const [leaveBalances, setLeaveBalances] = useState({
    SICK_LEAVE: 0,
    PERSONAL_LEAVE: 0,
    TWO_HOUR_PERMISSION: 0,
    WFH: 0,
  });

  const [open, setOpen] = useState(false);
  const [appComments, setAppComments] = useState("");
  const handleClickOpen = (type) => {
    setOpen(true);
    setDialogType(type);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (e) => {
    setSelectedLeave({
      ...selectedLeave,
      isHalfDay: e.target.checked,
    });
  };

  function formatDate(date) {
    if (date === null) return "";
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleDateChange = (e) => {
    const selectedDates = e.value;
    if (selectedDates.length === 1) {
      const startDate = new Date(selectedDates[0]);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);
      setDates([startDate, endDate]);
    } else {
      setDates(selectedDates);
    }
  };

  const option = [
    { value: "PENDING", label: "Pending" },
    { value: "APPROVED", label: "Approved" },
    { value: "REJECTED", label: "Rejected" },
    { value: "CANCELLED", label: "Cancelled" },
    { value: "APPROVEDANDCANCELLED", label: "Approved and Cancelled" },
  ];

  const [selectStatus, setSelectStatus] = useState(
    option.find((option) => option.value === "PENDING")
  );

  const displayedCards = data;

  console.log(displayedCards, "leeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${REPORTEES_URL.GET_REPORTEES_NAMES}${empId}`;
        const response = await axiosService.get(url);
        const data = response?.data?.data;

        if (data) {
          const employee = data.map((item) => {
            return {
              name: item.employeeFirstName + " " + item.employeeLastName,
              empId: item?.employeeId,
            };
          });
          setAttendanceData(employee);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "USER") {
      fetchData();
    }
  }, [empId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = REPORTEES_URL.ADMIN_REPORTEES_NAMES;
        const response = await axiosService.get(url);
        const data = response?.data?.data;

        if (data) {
          const employee = data.map((item) => {
            return {
              name: item.employeeFirstName + " " + item.employeeLastName,
              empId: item?.employeeId,
            };
          });
          setAttendanceData(employee);
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };

    if (role === "ADMIN") {
      fetchData();
    }
  }, [recordsPerPage, currentPage]);

  const handleEmployeeSelect = (e) => {
    setSingleSelections(e.value);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectStatus(selectedOption);
  };

  const getStatusButton = (leaveStatus) => {
    if (leaveStatus === "APPROVED") {
      return <div className="leave-list-active">Approved</div>;
    } else if (leaveStatus === "REJECTED") {
      return <div className="leave-list-inactive">Rejected</div>;
    } else if (leaveStatus === "PENDING") {
      return <div className="leave-list-pending">Pending</div>;
    } else if (leaveStatus === "CANCELLED") {
      return <div className="leave-list-cancel">Cancelled</div>;
    } else if (leaveStatus === "APPROVEDANDCANCELLED") {
      return <div className="leave-list-cancel">Approved and Cancelled</div>;
    }
    return null;
  };

  function convertMonthNumberToString(monthString) {
    let splittedValue = monthString.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthName = monthNames.find(
      (month, idx) => idx + 1 === parseInt(splittedValue[1])
    );
    splittedValue[1] = monthName;

    splittedValue.reverse();
    let joined = splittedValue.join(" ");

    return joined;
  }

  const fetchUserData = async () => {
    try {
      const url = `${REPORTEES_URL.GET_REPORTEES_LEAVES}${empId}`;
      const params = {
        empId,
        pageNumber: currentPage,
        itemsPerPage: recordsPerPage,
      };
      if (singleSelections) {
        params.employeeName = singleSelections;
      }
      if (dates !== null && dates.length === 2) {
        params.startDate = formatDate(dates[0]);
        params.endDate = formatDate(dates[1]);
      }

      if (selectStatus) {
        params.leaveStatus = selectStatus?.value;
      }
      const response = await axiosService.get(url, params);
      const data = response?.data?.data?.data;
      const totalElements = response?.data?.data?.totalElements;

      setData(data);
      setTotalElements(totalElements);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };
  const location = useLocation();

  useEffect(() => {
    if (role === "USER") {
      fetchUserData();
    }
  }, [
    location.pathname,
    dates,
    empId,
    currentPage,
    recordsPerPage,
    selectStatus,
    singleSelections,
  ]);

  const fetchAdminData = async () => {
    try {
      const url = REPORTEES_URL.ADMIN_REPORTEES_LEAVES;
      const params = {
        pageNumber: currentPage,
        itemsPerPage: recordsPerPage,
      };
      if (singleSelections) {
        params.employeeName = singleSelections;
      }
      if (dates !== null && dates.length === 2) {
        params.startDate = formatDate(dates[0]);
        params.endDate = formatDate(dates[1]);
      }

      if (selectStatus) {
        params.leaveStatus = selectStatus?.value;
      }

      const response = await axiosService.get(url, params);
      const data = response?.data?.data?.data;
      const totalElements = response?.data?.data?.totalElements;
      setData(data);
      setTotalElements(totalElements);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  useEffect(() => {
    if (role === "ADMIN") {
      fetchAdminData();
    }
  }, [
    location.pathname,
    dates,
    currentPage,
    recordsPerPage,
    selectStatus,
    singleSelections,
  ]);

  const fetchLeaveData = async (leaveId) => {
    try {
      const url = `${REPORTEES_URL.REPORTEES_APPLIED_LEAVE}${leaveId}`;

      const response = await axiosService.get(url);
      const data = response?.data?.data;

      setSelectedLeave(data);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const handleApproveLeave = async (leaveId) => {
    const formData = {
      leaveId,
      comments: appComments,
    };

    try {
      const response = await axiosService.post(
        `${REPORTEES_URL.REPORTEES_LEAVE_APPROVE}${leaveId}`,
        formData
      );
      toast.success("Leave approved successfully!", {
        autoClose: 3000,
        closeButton: false,
        style: { backgroundColor: "green", color: "white" },
      });
      handleClose();
      if (response.status === 200) {
        fetchAdminData();
        setSelectedLeave({});
        setAppComments("");
      }
    } catch (error) {
      console.error("Error:", error);

      setAppComments("");
    }
  };

  const handleRejectLeave = async (leaveId) => {
    const formData = {
      leaveId,
      comments: appComments,
    };
    try {
      const response = await axiosService.post(
        `${REPORTEES_URL.REPORTEES_LEAVE_REJECT}${leaveId}`,
        formData
      );
      toast.success("Leave rejected successfully!", {
        autoClose: 3000,
        closeButton: false,
        style: { backgroundColor: "green", color: "white" },
      });
      handleClose();
      if (response.status === 200) {
        fetchAdminData();
        setSelectedLeave({});
        setAppComments("");
      }
    } catch (error) {
      console.error("Error:", error);
      if (error?.response?.data?.message.startsWith("E")) {
        toast.error(error?.response?.data?.message.split(":")[1]);
      } else {
        toast.error(error?.response?.data?.message);
      }
      setAppComments("");
    }
  };

  const LeaveMaster = (empId) => {
    axiosService
      .get(`${LEAVE_URL.GET_EMPLOYEE_LEAVES}${empId}`)
      .then((res) => {
        // setAttData(res.data.data);
        setLeaveBalances({
          SICK_LEAVE: res.data.sickLeaveBalance,
          PERSONAL_LEAVE: res.data.personalLeaveBalance,
          TWO_HOUR_PERMISSION: res.data.permissionBalance,
          WFH: res.data.wfhBalance,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const employeeNameoptions = attendanceData.map((a) => ({
    label: a?.name,
    value: a?.empId,
  }));

  return (
    <>
      <div className="w-100 reportees-timesheet-list">
        <div className="reportees-timesheet-list-container">
          <div className="reportees-timesheet-list-banner">
            <div className="reportees-container-fluid">
              {/* Top section */}
              <div className="reportees-row">
                <div className="col w-100 reportees-timesheet-top-column">
                  <div className="reportees-attendance-list-filter-input-container">
                    <Dropdown
                      id="employee-search"
                      labelKey="employeeFirstName"
                      value={singleSelections}
                      onChange={handleEmployeeSelect}
                      options={employeeNameoptions}
                      placeholder="Employee"
                      filter={true}
                      showClear
                      className=" reportees-custom-dropdown w-full md:w-14rem"
                      style={{ zIndex: "0" }}
                    />
                  </div>
                  <div className="reportees-attendance-list-filter-input-container">
                    <Dropdown
                      id="basic-typeahead-single"
                      // name="leaveStatus"
                      labelKey="leaveStatus"
                      placeholder="Status"
                      value={selectStatus?.value}
                      onChange={handleStatusChange}
                      options={option}
                      optionLabel="label"
                      showClear
                      style={{ zIndex: "0" }}
                      className="reportees-custom-dropdown w-full md:w-14rem"
                    />
                  </div>
                  <div className="reportees-attendance-list-date-range">
                    <Calendar
                      id="calendarInput"
                      value={dates}
                      placeholder="Date Range"
                      onChange={handleDateChange}
                      selectionMode="range"
                      readOnlyInput
                      hideOnRangeSelection
                      className="reportees-attendance-list-status-input"
                    ></Calendar>
                    <div className="reportees-applied-leave-list-date-icon">
                      <Filter />
                    </div>
                  </div>
                </div>
                <div className="reportees-TimesheetScrollContainer">
                  {/* Table Section */}
                  <div className="row reportees-timesheetTableScroll">
                    <div className="reportees-timesheet-list-table-container">
                      <table className="reportees-timesheet-list-table">
                        <thead>
                          <tr>
                            <th style={{ width: "225px" }}>Employee Name</th>
                            <th style={{ width: "205px" }}>Applied On</th>
                            <th style={{ width: "205px" }}>Date</th>
                            <th style={{ width: "205px" }}>Duration</th>
                            <th style={{ width: "205px" }}>leaveType</th>
                            <th style={{ width: "205px" }}>Status</th>
                            <th style={{ width: "90px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayedCards.map((e, index) => (
                            <tr key={index}>
                              <td>
                                {e?.employee?.employeeFirstName}{" "}
                                {e?.employee?.employeeLastName}
                              </td>
                              <td>
                                {convertMonthNumberToString(e.appliedDate)}
                              </td>
                              <td>
                                {convertMonthNumberToString(e.startDate)} -{" "}
                                {convertMonthNumberToString(e.endDate)}
                              </td>
                              <td>{e.duration}</td>

                              <td>
                                {(e.leaveType === "PERSONAL_LEAVE"
                                  ? "CASUAL_LEAVE"
                                  : e.leaveType
                                )
                                  .split("_")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1).toLowerCase()
                                  )
                                  .join(" ")}
                              </td>

                              <td>{getStatusButton(e.leaveStatus)}</td>

                              <td>
                                <div style={{ cursor: "pointer" }}>
                                  <span
                                    id={e.leaveId}
                                    onClick={() => {
                                      if (
                                        e.leaveStatus?.toLowerCase() ===
                                        "pending"
                                      ) {
                                        fetchLeaveData(e.leaveId);
                                        handleClickOpen("approve");
                                        LeaveMaster(e?.employee?.empId);
                                      }
                                    }}
                                    style={
                                      e.leaveStatus?.toLowerCase() !== "pending"
                                        ? { opacity: "50%" }
                                        : {}
                                    }
                                  >
                                    <Approved />
                                  </span>
                                  <span
                                    style={
                                      e.leaveStatus?.toLowerCase() !== "pending"
                                        ? { opacity: "50%", marginLeft: "15px" }
                                        : { marginLeft: "15px" }
                                    }
                                    id={e.leaveId}
                                    onClick={() => {
                                      if (
                                        e.leaveStatus?.toLowerCase() ===
                                        "pending"
                                      ) {
                                        fetchLeaveData(e.leaveId);
                                        LeaveMaster(e?.employee?.empId);
                                        handleClickOpen("reject");
                                      }
                                    }}
                                  >
                                    <Reject />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className="row mt-3"
                  style={{ boxShadow: "0px -2px  #F3EEEA" }}
                >
                  <div className="col w-50 d-flex align-items-center reportees-timesheet-record-list">
                    <span>Showing</span>
                    <div style={{ width: "fit-content", position: "relative" }}>
                      <select
                        value={recordsPerPage}
                        onChange={handleRecordsPerPageChange}
                      >
                        {cardsPerPageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="reportees-timesheet-record-list-dropdown-icon">
                        <Dropdowns />
                      </div>
                    </div>
                  </div>
                  <div className="col w-50 reportees-timsheet-pagination-container">
                    {Math.ceil(totalElements / recordsPerPage) > 1 ? (
                      <>
                        {totalElements && (
                          <Pagination
                            className="pagination d-inline-flex"
                            currentPage={currentPage}
                            totalPages={Math.ceil(
                              totalElements / recordsPerPage
                            )}
                            onPageChange={onPageChange}
                            nextLabel={<RightArrow />}
                            previousLabel={<LeftArrow />}
                            activePageLinkStyle={{
                              color: "#ffffff !important",
                            }}
                            icon={<span>{currentPage}</span>}
                          />
                        )}
                      </>
                    ) : (
                      <nav
                        class="pagination d-inline-flex"
                        activepagelinkstyle="[object Object]"
                        icon="[object Object]"
                      >
                        <ul class="xs:mt-0 mt-2 inline-flex items-center -space-x-px">
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.4685 17.5856C14.7919 17.3269 14.8444 16.8549 14.5856 16.5315L10.9604 12L14.5856 7.46849C14.8444 7.14505 14.7919 6.67308 14.4685 6.41432C14.145 6.15556 13.673 6.208 13.4143 6.53145L9.41429 11.5315C9.19516 11.8054 9.19516 12.1946 9.41429 12.4685L13.4143 17.4685C13.673 17.7919 14.145 17.8444 14.4685 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                          <li aria-current="page">
                            <button
                              type="button"
                              class="w-12 border border-gray-300 py-2 leading-tight enabled:hover:bg-gray-100 enabled:hover:text-gray-700 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                            >
                              1
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              class="cursor-not-allowed opacity-50 ml-0 rounded-l-lg border border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.53157 17.5856C9.20812 17.3269 9.15568 16.8549 9.41444 16.5315L13.0396 12L9.41444 7.46849C9.15568 7.14505 9.20812 6.67308 9.53157 6.41432C9.85501 6.15556 10.327 6.208 10.5857 6.53145L14.5857 11.5315C14.8049 11.8054 14.8049 12.1946 14.5857 12.4685L10.5857 17.4685C10.327 17.7919 9.85501 17.8444 9.53157 17.5856Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog
            sx={{
              "& .MuiDialog-paper": { width: "40%", borderRadius: "20px" },
            }}
            maxWidth="100px"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paper: "custom-dialog-paper" }}
          >
            <DialogActions
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                borderBottom: "1px solid lightgrey",
                paddingLeft: "10px",
                paddingRight: "10px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <span>
                {dialogType === "approve" ? (
                  <p className="leave-title">Leave Approval</p>
                ) : (
                  <p className="leave-title">Leave Rejection</p>
                )}
              </span>
            </DialogActions>
            <DialogTitle id="alert-dialog-title">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "48%" }}>
                  <label className="applied-leave-label">Date Range</label>
                  <input
                    className="leave-duration"
                    name="Duration"
                    style={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "10px",
                      border: "1px solid rgba(162, 161, 168, 0.2)",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      color: "rgba(0, 0, 0, 1)",
                    }}
                    value={`${selectedLeave?.startDate} - ${selectedLeave?.endDate}`}
                    readOnly
                  ></input>
                </div>

                <div style={{ width: "48%" }}>
                  <label className="applied-leave-label">Duration</label>
                  <input
                    className="leave-duration"
                    name="Duration"
                    style={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "10px",
                      border: "1px solid rgba(162, 161, 168, 0.2)",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      color: "rgba(0, 0, 0, 1)",
                    }}
                    readOnly
                    value={selectedLeave?.duration}
                  ></input>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5px",

                  width: "100%",
                }}
              >
                <div style={{ width: "48%" }}>
                  <label className="applied-leave-label">Absence Type</label>
                  <input
                    className="leave-absence-type"
                    name="leaveType"
                    style={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "10px",
                      border: "1px solid rgba(162, 161, 168, 0.2)",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      color: "rgba(0, 0, 0, 1)",

                      alignItems: "center",
                    }}
                    value={selectedLeave?.leaveType}
                    readOnly
                  ></input>
                </div>
                <div style={{ width: "48%" }}>
                  <label className="applied-leave-label">Balance Leave</label>
                  <input
                    name="balanceType"
                    className="leave-balance-type"
                    value={
                      selectedLeave?.leaveType === "SICK_LEAVE"
                        ? leaveBalances?.SICK_LEAVE
                        : selectedLeave?.leaveType === "PERSONAL_LEAVE"
                        ? leaveBalances?.PERSONAL_LEAVE
                        : selectedLeave?.leaveType === "WFH"
                        ? leaveBalances?.WFH
                        : selectedLeave?.leaveType === "TWO_HOUR_PERMISSION"
                        ? leaveBalances?.TWO_HOUR_PERMISSION
                        : ""
                    }
                    style={{
                      width: "100%",
                      height: "48px",
                      borderRadius: "10px",
                      border: "1px solid rgba(162, 161, 168, 0.2)",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      color: "rgba(0, 0, 0, 1)",
                    }}
                    readOnly
                  ></input>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5px",
                  width: "100%",
                }}
              >
                {" "}
                {selectedLeave.isHalfDay && (
                  <div style={{ width: "48%", marginTop: "15px" }}>
                    <input
                      name="isHalfDay"
                      type="checkbox"
                      checked={selectedLeave?.isHalfDay}
                      onChange={handleCheckboxChange}
                      disabled
                    />

                    <label className="applied-leave-label-halfday">
                      Select Half Day
                    </label>
                  </div>
                )}
                {selectedLeave.isHalfDay && (
                  <div style={{ width: "48%" }}>
                    <label className="applied-leave-label">Start Day</label>
                    <input
                      className="leave-start-day"
                      name="halfType"
                      value={selectedLeave.halfType}
                      style={{
                        width: "100%",
                        height: "48px",
                        borderRadius: "10px",
                        border: "1px solid rgba(162, 161, 168, 0.2)",
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                        color: "rgba(0, 0, 0, 1)",

                        alignItems: "center",
                      }}
                      readOnly
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // marginTop: "5px",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label className="applied-leave-label">Comments</label>
                  <input
                    className="applied-leave-comments "
                    name="comments"
                    value={selectedLeave?.comments}
                    style={{
                      width: "100%",

                      borderRadius: "10px",
                      border: "1px solid rgba(162, 161, 168, 0.2)",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      color: "rgba(0, 0, 0, 1)",

                      alignItems: "center",
                    }}
                    readOnly
                  />
                </div>
                <div style={{ width: "100%", marginTop: "20px" }}>
                  <span className="p-float-label ">
                    <InputText
                      className="applied-leave-comments "
                      name="comments"
                      style={{
                        width: "100%",

                        borderRadius: "10px",
                        border: "1px solid rgba(162, 161, 168, 0.2)",
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                        color: "rgba(0, 0, 0, 1)",
                        marginTop: "10px",
                        alignItems: "center",
                      }}
                      onChange={(e) => setAppComments(e?.target?.value)}
                    />
                    <label className="applied-leave-floating-label">
                      {dialogType === "approve"
                        ? "Approve Comments"
                        : "Reject Comments"}
                    </label>
                  </span>
                </div>
              </div>
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                {dialogType === "approve" ? (
                  <button
                    className="applied-leave-approve-bttn"
                    onClick={() => {
                      if (
                        selectedLeave.leaveStatus?.toLowerCase() === "pending"
                      ) {
                        handleApproveLeave(
                          selectedLeave?.leaveId,
                          selectedLeave.leaveStatus
                        );
                      }
                    }}
                  >
                    APPROVE
                  </button>
                ) : (
                  <button
                    className="applied-leave-reject-bttn"
                    onClick={() => {
                      if (
                        selectedLeave.leaveStatus?.toLowerCase() === "pending"
                      ) {
                        handleRejectLeave(
                          selectedLeave?.leaveId,
                          selectedLeave.leaveStatus
                        );
                      }
                    }}
                  >
                    REJECT
                  </button>
                )}
                <div className="">
                  <button
                    type="submit"
                    className="applied-leave-cancel-bttn"
                    onClick={handleClose}
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </DialogTitle>

            <DialogContent>
              <DialogContentText></DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default ReporteesAppliedLeaves;
